(function ($) {
    $( document ).on("keypress", "#publication-keyword", function( e ) {
        if( e.which == 13 ){
            e.preventDefault();
            $('#publication-submit' ).click();
        }
    });

    $(function () {

        var filterData = {};

        $('#publication-submit' ).click(function() {

            var key_word = '';
            var key_word = $("#publication-keyword").val();
            var topic_id = '';
            var topic_id = $("#publication-topic").val();
            var practice_id = '';
            var practice_id = $("#publication-practice").val();

            if( typeof key_word !== 'undefined' ) {
                filterData['key_word'] = key_word;
            }

            if( typeof topic_id !== 'undefined' ) {
                filterData['topic_id'] = topic_id;
            }

            if( typeof practice_id !== 'undefined' ) {
                filterData['practice_id'] = practice_id;
            }

            publications_ajax_get ( topic_id, practice_id, filterData );

        });

        function publications_ajax_get( topic_id, practice_id, filterData) {

            $('.publication-overlay').css({"opacity": "1", "z-index": 999});

            $.ajax({
                type: 'POST',
                url: ajax_path.ajaxurl,
                security: ajax_path.ajaxnonce,
                data: {"action": 'load-publications', topic_id: topic_id, practice_id: practice_id, filterData: filterData },
                success: function(response) {
                    $('#publication-results').html(response);
                    $('#publication-results-list-latest, .publication-results-list-latest-title').hide();
                    $('#response-call').show();

                    setTimeout(function(){
                        $('.publication-overlay').css({"opacity": "0", "z-index": -1});
                    }, 500);

                    return false;
                }
            });
        }

    });

})(jQuery, this);